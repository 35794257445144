import { IPricing } from 'api/OwnPricingApiClient'
import { BusyIndicator, DPFormField, DPInputNumber, PageHeader, useToast } from '@framework'
import { useSession } from '@hooks/useSession'
import { useTranslation } from '@hooks/useTranslation'
import { Formik } from 'formik'
import React from 'react'
import { Form, Message } from 'semantic-ui-react'
import * as Yup from 'yup'
import styles from './OwnPricingPage.module.css'
import useAgreementPricing from '../../../infrastructure/hooks/useAgreementPricing'
import { DPButton } from 'shared'
import DPTable from 'infrastructure/framework/DPForms/DPTable'

export function OwnPricingPage(){
    const session = useSession()
    const toast = useToast()
    const $t = useTranslation('OwnPricingPage')
    const purchaseAgreementPricing = useAgreementPricing(session.currentDealer?.id, 'purchase')
    const unsecureAgreementPricing = useAgreementPricing(session.currentDealer?.id, 'unsecure')
    const leasingAgreementPricing = useAgreementPricing(session.currentDealer?.id, 'leasing')

    const isPricingUnchanged = (origin: IPricing, current: IPricing) =>
        origin.variableInterest === current.variableInterest &&
        origin.fixedInterest === current.fixedInterest &&
        origin.dealerCommitmentCommissionMin === current.dealerCommitmentCommissionMin &&
        origin.dealerCommitmentCommissionPct === current.dealerCommitmentCommissionPct &&
        origin.documentFee === current.documentFee
    const isSaveButtonDisable = (isPricingUnchanged: boolean, hasError: boolean) =>
        isPricingUnchanged || hasError

    const validationSchema = Yup.object({
        purchase: Yup.object({
            variableInterest: Yup.number().min(-3).max(3).required().label($t('variableInterest')),
            fixedInterest: Yup.number().min(-3).max(3).required().label($t('fixedInterest')),
            dealerCommitmentCommissionMin: Yup.number().min(0).max(15000).required().label($t('dealerCommitmentCommission')),
            dealerCommitmentCommissionPct: Yup.number().min(0).max(4).required().label($t('dealerCommitmentCommission')),
            documentFee: Yup.number().min(0).max(3000).required().label($t('documentFee')),
        }),
        unsecure: Yup.object({
            variableInterest: Yup.number().min(-3).max(3).required().label($t('variableInterest')),
            dealerCommitmentCommissionMin: Yup.number().min(0).max(7000).required().label($t('dealerCommitmentCommission')),
            dealerCommitmentCommissionPct: Yup.number().min(0).max(4).required().label($t('dealerCommitmentCommission')),
            documentFee: Yup.number().min(0).max(3000).required().label($t('documentFee')),
        }),
        leasing: Yup.object({
            variableInterest: Yup.number().min(-3).max(3).required().label($t('variableInterest')),
            fixedInterest: Yup.number().min(-3).max(3).required().label($t('fixedInterest')),
            documentFee: Yup.number().min(0).max(15000).required().label($t('documentFee')),
        })
    })
    const initialValues = {
        purchase: purchaseAgreementPricing.pricing,
        unsecure: unsecureAgreementPricing.pricing,
        leasing: leasingAgreementPricing.pricing,
    }
    const isBusy =
        purchaseAgreementPricing.isBusy ||
        unsecureAgreementPricing.isBusy ||
        leasingAgreementPricing.isBusy
    const isInitialized =
        purchaseAgreementPricing.isInitialized &&
        unsecureAgreementPricing.isInitialized &&
        leasingAgreementPricing.isInitialized
    return(
        <>
            <PageHeader text={$t('ownPricing')}/>
            <Message warning>Bemærk ændring af egen prissætning først tager effekt på nye sager, ca. 5 min. efter at der er klikket "Gem".</Message>
            <BusyIndicator busy={isBusy}>
                {isInitialized &&
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={() => {}}
                    >
                        {props => (
                            <Form>
                                <DPTable celled>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}/>
                                            <th>
                                                {$t('variableInterest')}
                                            </th>
                                            <th>
                                                {$t('fixedInterest')}
                                            </th>
                                            <th colSpan={2}>
                                                {$t('dealerCommitmentCommission')}
                                            </th>
                                            <th>
                                                {$t('documentFee')}
                                            </th>
                                            <th rowSpan={2}/>
                                        </tr>
                                        <tr>
                                            {/* Apply custom styles to add missing left border (bug in SematicUI) */}
                                            <th className={styles['border-left']}>
                                                {$t('addOn')}
                                            </th>
                                            <th>
                                                {$t('addOn')}
                                            </th>
                                            <th>
                                                Min kr.
                                            </th>
                                            <th>
                                                %
                                            </th>
                                            <th>
                                                Kr.
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={styles['tbody']}>
                                        <tr>
                                            <td>
                                                <b>{$t('purchase')}</b>
                                            </td>
                                            <td>
                                                <DPFormField name="purchase.variableInterest" inline={false}>
                                                    <DPInputNumber name="purchase.variableInterest" precision={2} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="purchase.fixedInterest" inline={false}>
                                                    <DPInputNumber name="purchase.fixedInterest" precision={2} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="purchase.dealerCommitmentCommissionMin" inline={false}>
                                                    <DPInputNumber name="purchase.dealerCommitmentCommissionMin" precision={0} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="purchase.dealerCommitmentCommissionPct" inline={false}>
                                                    <DPInputNumber name="purchase.dealerCommitmentCommissionPct" precision={2} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="purchase.documentFee" inline={false}>
                                                    <DPInputNumber name="purchase.documentFee" precision={0} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPButton
                                                    type="button"
                                                    size="mini"
                                                    onClick={() => {
                                                        purchaseAgreementPricing
                                                            .update(props.values.purchase!)
                                                            .then(() => toast.success($t('saved')))
                                                    }}
                                                    disabled={
                                                        isSaveButtonDisable(
                                                            isPricingUnchanged(initialValues.purchase!, props.values.purchase!),
                                                            props.errors.purchase !== undefined)
                                                    }
                                                >
                                                    {$t('save')}
                                                </DPButton>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <b>{$t('unsecure')}</b>
                                            </td>
                                            <td>
                                                <DPFormField name="unsecure.variableInterest" inline={false}>
                                                    <DPInputNumber name="unsecure.variableInterest" precision={2} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="unsecure.fixedInterest" inline={false}>
                                                    <DPInputNumber name="unsecure.fixedInterest" disabled />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="unsecure.dealerCommitmentCommissionMin" inline={false}>
                                                    <DPInputNumber name="unsecure.dealerCommitmentCommissionMin" precision={0} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="unsecure.dealerCommitmentCommissionPct" inline={false}>
                                                    <DPInputNumber name="unsecure.dealerCommitmentCommissionPct" precision={2} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="unsecure.documentFee" inline={false}>
                                                    <DPInputNumber name="unsecure.documentFee"  precision={0} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPButton
                                                    type="button"
                                                    size="mini"
                                                    onClick={() =>
                                                        unsecureAgreementPricing
                                                            .update(props.values.unsecure!)
                                                            .then(() => toast.success($t('saved')))
                                                    }
                                                    disabled={
                                                        isSaveButtonDisable(
                                                            isPricingUnchanged(initialValues.unsecure!, props.values.unsecure!),
                                                            props.errors.unsecure !== undefined)
                                                    }
                                                >
                                                    {$t('save')}
                                                </DPButton>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <b>{$t('leasing')}</b>
                                            </td>
                                            <td>
                                                <DPFormField name="leasing.variableInterest" inline={false}>
                                                    <DPInputNumber name="leasing.variableInterest" precision={2} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="leasing.fixedInterest" inline={false}>
                                                    <DPInputNumber name="leasing.fixedInterest" precision={2} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="leasing.dealerCommitmentCommissionMin" inline={false}>
                                                    <DPInputNumber name="leasing.dealerCommitmentCommissionMin" precision={0} disabled />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="leasing.dealerCommitmentCommissionPct" inline={false}>
                                                    <DPInputNumber name="leasing.dealerCommitmentCommissionPct" precision={2} disabled />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPFormField name="leasing.documentFee" inline={false} tooltip='Establishment fee'>
                                                    <DPInputNumber name="leasing.documentFee" precision={0} />
                                                </DPFormField>
                                            </td>
                                            <td>
                                                <DPButton
                                                    type="button"
                                                    size="mini"
                                                    onClick={() => {
                                                        leasingAgreementPricing
                                                            .update(props.values.leasing!)
                                                            .then(() => toast.success($t('saved')))
                                                    }}
                                                    disabled={
                                                        isSaveButtonDisable(
                                                            isPricingUnchanged(initialValues.leasing!, props.values.leasing!),
                                                            props.errors.leasing !== undefined)
                                                    }
                                                >
                                                    {$t('save')}
                                                </DPButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </DPTable>
                            </Form>
                        )}
                    </Formik>
                }
            </BusyIndicator>
        </>
    )
}