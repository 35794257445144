import { BusyIndicator, DPFormField, DPInputPassword, DPInputText, PageSectionHeader } from "@framework";
import { useApiClient } from "@hooks/useApiClient";
import { PublicApiClient } from "api";
import { Formik } from "formik";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Form, Message } from "semantic-ui-react";
import { DPButton } from "shared";
import { PublicCardLayout } from "shared/layout";
import * as Yup from 'yup'
import { routes } from "routing";

export function NewUserPage(){
    const [searchParams] = useSearchParams();
    const [sent, setSent] = useState(false);

    const email = searchParams.get('email')
    const token = searchParams.get('token')

    return (
        <PublicCardLayout>
            <PageSectionHeader children="Ny bruger" />
            { sent && <SuccessMessage /> }
            <NewUserForm email={email} token={token} sent={sent} setSent={setSent} />
            <Requirements />
        </PublicCardLayout>
    )
}

interface FormModel {
    email: string | null,
    password: string | null,
    passwordConfirmation: string | null,
    username: string | null,
    usernameConfirmation: string | null,
}

function NewUserForm(props: {
    email: string | null,
    token: string | null,
    sent: boolean,
    setSent: (value: boolean) => void
}){
    const api = useApiClient(x => new PublicApiClient(x));
    const [busy, setBusy] = useState(false);

    const onSubmit = (model: FormModel) => {
        setBusy(true);
        api.newUser({ email: props.email, token: props.token, password: model.password, username: model.username })
            .then(x => {
                if(x.success){
                    props.setSent(true)
                }
            })
            .finally(() => setBusy(false));
    };

    const initialValues = {
        email: props.email ?? "",
        password: "",
        passwordConfirmation: "",
        username: "",
        usernameConfirmation: "",
    } as FormModel;

    const validationSchema = () => Yup.object().shape({
        email: Yup.string(),
        password: Yup.string().required().label("Ny adgangskode"),
        passwordConfirmation: Yup
            .string()
            .required()
            .label("Genindtast ny adgangskode")
            .oneOf([Yup.ref("password")], "Adgangskoder matcher ikke"),
        username: Yup.string().required().label("Nyt brugernavn"),
        usernameConfirmation: Yup
            .string()
            .required()
            .label("Genindtast nyt brugernavn")
            .oneOf([Yup.ref("username")], "Brugernavn matcher ikke"),
    });

    return (
        <BusyIndicator busy={busy}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {formikProps => 
                    <Form onSubmit={formikProps.handleSubmit}>
                        <DPFormField label="E-mail" name="email" inline={false}>
                            <DPInputText name="email" disabled={true} />
                        </DPFormField>

                        <DPFormField label="Ny adgangskode" name="password" inline={false}>
                            <DPInputPassword name="password" disabled={props.sent} />
                        </DPFormField>

                        <DPFormField label="Genindtast ny adgangskode" name="passwordConfirmation" inline={false}>
                            <DPInputPassword name="passwordConfirmation" disabled={props.sent} />
                        </DPFormField>

                        <DPFormField label="Nyt brugernavn" name="username" inline={false}>
                            <DPInputText name="username" disabled={props.sent} />
                        </DPFormField>

                        <DPFormField label="Genindtast nyt brugernavn" name="usernameConfirmation" inline={false}>
                            <DPInputText name="usernameConfirmation" disabled={props.sent} />
                        </DPFormField>

                        <DPButton type="submit" children="Gem" disabled={props.sent} />
                    </Form>
                }
            </Formik>
        </BusyIndicator>
    )
}

function SuccessMessage(){
    return (
        <Message positive>
            <p>Dine loginoplysninger er blevet gemt. Login med det nye brugernavn og adgangkode på den næste side.</p>
            <Link id="login-button" to={routes.logIn()} className="ui button">Login</Link>
        </Message>
    )
}

// TODO: Consider to move the requirements to separate component and use flags to preset
// requirements for username, password or both of them.
function Requirements(){
    return (
        <div style={{ marginTop: "2rem" }}>
            <p>Regler for brugernavn:</p>
            <ul>
                <li>Skal være længere end 4 tegn.</li>
                <li>Må ikke være dit cpr nummer.</li>
                <li>Må ikke være det samme som din adgangskode.</li>
            </ul>

            <p>Regler for adgangskode</p>
            <ul>
                <li>Minimum 8 karakterer</li>
                <li>Mindst ét lille bogstav</li>
                <li>Mindst ét stort bogstav</li>
                <li>Mindst ét symbol eller tal</li>
                <li>Ingen mellemrum</li>
                <li>Nyt kodeord må ikke være en del af det gamle kodeord eller omvendt</li>
                <li>Må ikke have været brugt inden for 1 år</li>
                <li>Må ikke være det samme som dit brugernavn</li>
            </ul>

            <p>
                Har du spørgsmål?
                Kontakt Salessupport på:
                <ul>
                    <li>telefon: <a href="tel:70 22 58 22" className="text-nowrap">70 22 58 22</a>,<br/></li>
                    <li>mail: <a href="mailto:salessupport@santanderconsumer.dk" className="text-nowrap">salessupport@santanderconsumer.dk</a>.</li>
                </ul>
            </p>
        </div>
    )
}