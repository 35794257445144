import { BusyIndicator, DPFormField, DPInputPassword, DPInputText, PageSectionHeader } from "@framework";
import { useApiClient } from "@hooks/useApiClient";
import { PublicApiClient } from "api";
import { Formik } from "formik";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Message } from "semantic-ui-react";
import { DPButton } from "shared";
import { PublicCardLayout } from "shared/layout";
import * as Yup from 'yup'

export function ResetPasswordPage(){
    const [searchParams] = useSearchParams();
    const [sent, setSent] = useState(false);

    const username = searchParams.get('username')
    const token = searchParams.get('token')

    return (
        <PublicCardLayout>
            <PageSectionHeader children="Indtast ny adgangskode" />
            { sent &&
                <Message positive>
                    Ændring af adgangskode er fuldført. Din adgangskode er ændret.
                </Message>
            }
            <ResetPasswordForm
                username={username}
                token={token}
                sent={sent}
                setSent={setSent}
            />
            <PasswordRequirements />
        </PublicCardLayout>
    )
}

interface FormModel {
    username: string | null,
    password: string | null,
    passwordConfirmation: string | null,
}

function ResetPasswordForm(props: {
    username: string | null,
    token: string | null,
    sent: boolean,
    setSent: (value: boolean) => void
}){
    const api = useApiClient(x => new PublicApiClient(x));
    const [busy, setBusy] = useState(false);

    const onSubmit = (model: FormModel) => {
        setBusy(true);
        api.resetPassword({ username: model.username, token: props.token, password: model.password })
            .then(x => {
                if(x.success){
                    props.setSent(true)
                }
            })
            .finally(() => setBusy(false));
    };

    const initialValues = {
        username: props.username ?? "",
        password: "",
        passwordConfirmation: "",
    } as FormModel;

    const validationSchema = () => Yup.object().shape({
        username: Yup.string(),
        password: Yup.string().required().label("Ny adgangskode"),
        passwordConfirmation: Yup
            .string()
            .required()
            .label("Genindtast ny adgangskode")
            .oneOf([Yup.ref("password")], "Adgangskoder matcher ikke"),
    });

    return (
        <BusyIndicator busy={busy}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {formikProps => 
                    <Form onSubmit={formikProps.handleSubmit}>
                        <DPFormField label="Brugernavn" name="username" inline={false}>
                            <DPInputText name="username" disabled={true} />
                        </DPFormField>

                        <DPFormField label="Ny adgangskode" name="password" inline={false}>
                            <DPInputPassword name="password" disabled={props.sent} />
                        </DPFormField>

                        <DPFormField label="Genindtast ny adgangskode" name="passwordConfirmation" inline={false}>
                            <DPInputPassword name="passwordConfirmation" disabled={props.sent} />
                        </DPFormField>

                        <DPButton type="submit" children="Gem" disabled={props.sent} />
                    </Form>
                }
            </Formik>
        </BusyIndicator>
    )
}

function PasswordRequirements(){
    return (
        <div style={{ marginTop: "2rem" }}>
            <p>Adgangskodekrav</p>
            <ul>
                <li>Minimum 8 karakterer</li>
                <li>Mindst ét lille bogstav</li>
                <li>Mindst ét stort bogstav</li>
                <li>Mindst ét symbol eller tal</li>
                <li>Ingen mellemrum</li>
                <li>Nyt kodeord må ikke være en del af det gamle kodeord eller omvendt</li>
                <li>Må ikke have været brugt inden for 1 år</li>
                <li>Må ikke være det samme som dit brugernavn</li>
            </ul>
            <p>
                Har du spørgsmål?
                Kontakt Salessupport på:
                <ul>
                    <li>telefon: <a href="tel:70 22 58 22" className="text-nowrap">70 22 58 22</a>,<br/></li>
                    <li>mail: <a href="mailto:salessupport@santanderconsumer.dk" className="text-nowrap">salessupport@santanderconsumer.dk</a>.</li>
                </ul>
            </p>
        </div>
    )
}