export enum KnownBrandCode{
    Santander = 1,
    Nissan = 5,
    Ford = 402,
    Hessel = 14,
    Maxus = 15,
    Yamaha = 19,
    PChristensenFinans = 18,
    Fisker = 17,
    Mazda = 22,
} 