import { ApiClient } from '@api'
import { FileFromResponse } from '@api/models'
import { Application, IDropdownOption } from 'model'
import { ApplicationType } from 'shared'
import { toQueryString } from './toQueryString'

interface ISubmitApplicationResponse {
  externalReference: string
}

interface IGeneratePriceTagRequest {
  agreementCode: number,
  brandCode: number,
  car: {
    engine: string,
    horsePower: string,
    gearbox: string,
    fuelEfficiency: string,
    greenOwnerTax: string,
    equipment: string,
    firstRegistrationDate: string | undefined | null,
    mileage: number | undefined | null,
    make: string | undefined | null,
    model: string | undefined | null,
    variant: string | undefined | null,
    modelYear: string | undefined | null,
  },
  loan: {
    aop: number | undefined | null,
    baseAmount: number | undefined | null,
    downPayment: number | undefined | null,
    financedAmount: number | undefined | null,
    interestType: number | undefined | null,
    nominalInterestRate: number | undefined | null,
    objectPrice: number | undefined | null,
    paymentPerMonth: number | undefined | null,
    paymentTerms: number | undefined | null,
    totalLoanCost: number | undefined | null,
    totalRepay: number | undefined | null,
  }
}

export interface IOfferDocumentRequest {
  calculation: {
    additionalKm: number,
    administrationFee: number,
    agreementCode: number,
    aop: number,
    aopBeforeTaxRaw: number,
    calculateEarnings: boolean | null,
    changedParameter: number | null | undefined,
    commitmentFee: number,
    deliveryDate: string | null,
    documentFee: number,
    downPayment: number,
    financedAmount: number,
    firstRegistrationDate: string | null,
    includePayments: boolean,    
    insuranceAdminFee: number,
    interestRate: number,
    interestType: number,
    invoiceFee: number,
    kmPerYear: number,
    mileage: number | null,
    nomInterest: number,
    objectAgeMonths: number | null,
    objectPrice: number | null,
    objectType: number,
    paymentFreePeriods: number | null,
    paymentPerMonth: number,
    paymentTerms: number,
    residualValue: number,
    salesPersonId: string | null,
    serviceAgreementPrMonth: number | null,
    serviceGuarantor: number | null,
    serviceMonths: number | null,
    showMoneyLine: boolean,
    stempelTinglysning: number,
    terminationFee: number,
    totalLoanCosts: number,
    totalRepay: number,
  },
  customer: {
    address: string | null,
    city: string | null,
    email: string | null,
    firstName?: string | null,
    lastName?: string | null,
    zip: string | null
  },
  financialObject?: {
    brandName: string,
    deliveryDate: string,
    milage: number,
    modelName: string,
    objectPrice: number,
    objectType: number,
    variantName: string
  },
  isLoan: boolean,
  isPrivate: boolean,
  isUnSecure: boolean,
  serviceAgreementPrMonth: number
}

export class ApplicationApiClient {
  constructor(protected apiClient: ApiClient) { }

  public submitApplication = async (params: {
    dealerId: number,
    brandCode: number,
    incomingApplicationId?: string,
    application: Application,
    configuration?: { retries: number },
  }) => {
    const query = {
      brandCode: params.brandCode,
      incomingApplicationId: params.incomingApplicationId,
    };
    const url = `api/dealers/${params.dealerId}/applications/submit?${toQueryString(query)}`
    return this.apiClient.post<ISubmitApplicationResponse>(url, params.application, params.configuration)
  }

  public cancelApplication = async(dealerId: number, referenceId: string) => {
    const url = `api/dealers/${dealerId}/applications/${referenceId}/cancel`
    return this.apiClient.post(url)
  }

  public generatePriceTag = async (dealerId: number, request: IGeneratePriceTagRequest) => {
    const url = `api/dealers/${dealerId}/applications/generate-price-tag`
    return this.apiClient.post(url, request).then(x => {
      if(!x.success){
        return
      }

      return new FileFromResponse(x.response!).download()
    })
  }

  public generateOfferDocument = async(dealerId: number, request: IOfferDocumentRequest) => {
    const url = `api/dealers/${dealerId}/applications/generate-offer-document`
    return this.apiClient.post(url, request).then(x => {
      if(!x.success){
        return
      }

      return new FileFromResponse(x.response!).download()
    })
  }

  public getDefaultApplication = async(dealerId: number, type: ApplicationType) => {
    const url = `api/dealers/${dealerId}/applications/default`
    return this.apiClient.get<Application>(url, { type }).then(x => x.data)
  }

  public getStatuses = (dealerId: number) => {
    const url = `api/dealers/${dealerId}/applications/statuses`
    return this.apiClient.get<Array<IDropdownOption<string>>>(url).then(x => x.data)
  }
}